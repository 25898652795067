/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.video-block {
  overflow: hidden;
  width: 100%;
}
.video-block .video-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1440px;
  padding: 70px 16px;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  .video-block .video-wrapper {
    padding: 100px 16px;
  }
  .video-block .video-wrapper.video-left {
    justify-content: flex-start;
  }
  .video-block .video-wrapper.video-right {
    justify-content: flex-end;
  }
}
.video-block .video-inner {
  width: 100%;
}
.video-block .vimeo-video,
.video-block .youtube-video {
  position: relative;
  padding: 56.25% 0 0;
}
.video-block .youtube-video {
  overflow: hidden;
  position: relative;
}
.video-block .youtube-video iframe {
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.video-block .custom-video video {
  max-width: 100%;
}
@media only screen and (max-width: 1024px) {
  .video-block .custom-video video {
    height: auto;
  }
}